
.clickable-rows::v-deep {
    tbody tr td {
        cursor: pointer;
    }
    
    .el-table__expanded-cell {
        cursor: default;
    }
}
